import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const contactos = () => {
  return (
    <Layout>
      <Seo title="Contactos" defer={false} />
      <div className="w-full mx-auto flex flex-col lg:mt-10 ">
        <div className="flex flex-col w-full h-20 bg-gray-200 justify-center items-center text-md  lg:h-32 lg:text-xl lg:font-medium">
          <h1>Contacte-nos</h1>
          <span className="text-xs font-extralight lg:text-base">
            Teremos todo o prazer em ajudar.
          </span>
        </div>
        <main>
          <div className=" lg:flex lg:flex-row lg:w-4/5 lg:mx-auto lg:justify-center lg:items-center ">
            <div className="flex flex-col w-4/5 mx-auto mt-5 lg:w-9/12 lg:ml-9">
              <div className="flex flex-col text-xs w-full lg:text-lg">
                <h1 className="text-sm font-medium lg:text-xl">Morada:</h1>
                <h2 className="font-extralight">Restaurante Monte Alegre</h2>
                <span className="font-extralight">
                  Av. Padre João Fernandes Machado 230
                  <br />
                  4800-090 Fermentões
                </span>
              </div>
              <div className="border-b border-solid border-black mt-2 opacity-20 lg:w-9/12"></div>
              <div className="text-xs mt-2 lg:text-lg">
                <h1 className="text-sm font-medium lg:text-xl">Email:</h1>
                <span className="font-extralight">
                  montealegrestaurant@gmail.com
                </span>
              </div>
              <div className="border-b border-solid border-black mt-2 opacity-20 lg:w-9/12"></div>
            </div>

            <article className=" w-4/5 mx-auto mt-5 grid place-items-center justify-center lg:w-full">
              <h3 className="text-sm font-semibold text-red-700 lg:text-lg">
                Contacta-nos
              </h3>
              <form
                action="https://formspree.io/f/xrgjgone"
                method="POST"
                className=" mt-4"
              >
                <div className=" text-center w-72 border shadow-sm transition-all hover:shadow-md contact-form ">
                  <input
                    type="text"
                    placeholder="Nome Completo"
                    name="name"
                    className="block w-full p-2 border-none mt-1 text-xs lg:text-lg"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    className=" block w-full p-2 border-none mt-1 text-xs lg:text-lg"
                  />
                  <textarea
                    id=""
                    rows="5"
                    placeholder="Mensagem"
                    name="message"
                    className="block w-full p-2 border-none mt-1 text-xs lg:h-56 lg:text-lg"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="block w-full p-2 border-none mt-1 text-xs font-semibold bg-yellow-500 text-white lg:text-lg"
                >
                  Enviar
                </button>
              </form>
            </article>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default contactos
